<template>
  <div>
    <div v-if="variant === 'multiple' || variant === 'by_emp_type'" class="be-card-list-service">
      <div
          :class="visible ? 'not-collapsed' : 'collapsed'"
          :aria-expanded="visible"
          v-ripple
          class="be-card-list-service__inner"
      >
        <span class="be-card-list-service__btn btn-collapse">
          <i v-if="variants.some(i => i.selected)" class="has-selected icon-be-check"></i>
          <i class="btn-arrow icon-be-arrow-right"></i>
        </span>
        <div class="be-card-list-service__info">
          <div class="d-flex justify-content-between">
            <h4 class="be-card-list-service__info-title">{{ title }}</h4>
            <span class="be-card-list-service__info-price">
              {{ $t("from") | filteredSmallPrice(variants, variants[0].price_formatted, type, priceData) }}
            </span>
          </div>
          <div class="be-card-list-service__info-additional">
            <span class="time">
              {{ variants[0].duration }} {{ $t("b2c_minute_short") }} -
              {{ variants[variants.length - 1].duration }}{{ $t("b2c_minute_short") }}
            </span>
            <span v-if="type === 'bookable_phone'" class="caption">{{$t("b2c_book_by_phone_only")}}</span>
          </div>
          <be-link
              v-if="IsDescription"
              @click="$emit('show-details', id)"
              class="fz-14 show-details"
              variant="info"
          >{{ $t('b2c_show_details') }}
          </be-link>
        </div>
        <span @click="visible = !visible" class="be-card-list-service__toggle"/>
      </div>
      <b-collapse :id="`${id}`" v-model="visible">
        <div class="be-card-list-service__collapse-body">
          <div
              v-for="item in variants"
              :key="item.id"
              v-ripple
              class="be-card-list-service">
            <div class="be-card-list-service__inner" :class="{'selected': item.selected}">
              <template v-if="type !== 'bookable_phone'">
                <span v-if="item.selected" class="be-card-list-service__btn btn-selected-outline">
                  <i class="icon-be-check"></i>
                </span>
                <span v-else class="be-card-list-service__btn btn-add"/>
              </template>
              <div class="be-card-list-service__info">
                <div class="d-flex justify-content-between">
                  <h4 class="be-card-list-service__info-title">{{ item.caption }}</h4>
                  <span class="be-card-list-service__info-price">{{ item.price_formatted }}</span>
                </div>
                <div class="be-card-list-service__info-additional">
                  <span class="time">{{ item.duration }} {{ $t("b2c_minute_short") }}</span>
                </div>
              </div>

              <span
                  v-if="type !== 'bookable_phone'"
                  @click="!item.selected ?
                  selectService({
                    service_id: id,
                    variant_id: item.id
                  }) :
                  removeService(item.id)"
                  class="be-card-list-service__toggle"/>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <div v-else v-ripple class="be-card-list-service">
      <div class="be-card-list-service__inner" :class="{'selected': variants[0].selected}">
        <template v-if="type !== 'bookable_phone'">
          <span v-if="variants[0].selected" class="be-card-list-service__btn btn-selected">
            <i class="icon-be-check"></i>
          </span>
          <span v-else class="be-card-list-service__btn btn-add"/>
        </template>

        <div class="be-card-list-service__info">
          <div class="d-flex justify-content-between">
            <h4 class="be-card-list-service__info-title">{{ title }}</h4>
            <span class="be-card-list-service__info-price">{{ variants[0].price_formatted }}</span>
          </div>
          <div class="be-card-list-service__info-additional">
           <span>
              <span class="time">{{ variants[0].duration }} {{ $t("b2c_minute_short") }}</span> | <span
               class="group-name">{{ groupTitle }}</span>
           </span>
            <span v-if="type === 'bookable_phone'" class="caption">{{$t("b2c_book_by_phone_only")}}</span>
          </div>
          <be-link
              v-if="IsDescription"
              @click="$emit('show-details', id)"
              class="fz-14 show-details"
              variant="info"
          >{{ $t('b2c_show_details') }}
          </be-link>
        </div>
        <span
            v-if="type !== 'bookable_phone'"
            @click="!variants[0].selected ?
            selectService({
              service_id: id,
              variant_id: variants[0].id
            }) :
            removeService(variants[0].id)"
            class="be-card-list-service__toggle"/>
      </div>
    </div>
  </div>
</template>

<script>
import BeLink from "@/components/common/BeLink";
import {priceFormatter} from "@/utils/common";

export default {
  name: "BeCardListService",
  components: {BeLink},
  props: {
    variant: {
      type: String,
      default: 'single'
    },
    variants: {
      type: Array
    },
    id: {
      type: [Number, String]
    },
    title: {
      type: String
    },
    type: {
      type: String
    },
    groupTitle: {
      type: String
    },
    employees: {
      type: Array
    },
    IsDescription: {
      type: Boolean
    }
  },
  filters: {
    filteredSmallPrice(from, arr, text, type, format) {
      if (type === "hide_price") {
        return text;
      } else {
        const smaller = arr.reduce((a, b) => {
          if (a.price !== 0 && b.price !== 0) {
            if (a.price < b.price) {
              return a;
            }
            return b;
          }
          return a;
        });

        return `${from} ${priceFormatter(smaller.price, format)}`;
      }
    }
  },
  computed: {
    priceData() {
      return this.$store.getters.priceData
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    selectService(payload) {
      this.$store.commit('selectService', payload)
    },
    removeService(variantId) {
      this.$store.commit('removeService', variantId)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/global/variables";

.be-card-list-service {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;

  &__inner {
    padding: 16px 15px 16px 63px;
    border-bottom: 1px solid $border-color;
    position: relative;
    cursor: pointer;
    transition: .2s;

    &.selected {
      background: $white-100;
    }

    &.not-collapsed {
      .be-card-list-service__btn {
        &.btn-collapse {
          background: $primary-color;
          border-color: $primary-color;
          color: $white;

          .btn-arrow {
            top: 47%;
            transform: translate(-50%, -50%) rotate(-90deg);
          }
        }
        .has-selected {
          opacity: 0;
        }
      }
    }
  }

  &__toggle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .show-details {
    position: relative;
    z-index: 10;
  }

  &__btn {
    position: absolute;
    left: 15px;
    top: 16px;

    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $text-color;
    cursor: pointer;

    &.btn-add {
      &:before {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:after {
        content: '';
        position: absolute;
        width: 15px;
        height: 2px;
        background: $text-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: .3s;
      }
    }

    &.btn-selected {
      background: $primary-color;
      border-color: $primary-color;
      color: $white;

      i {
        position: absolute;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.btn-selected-outline {
      border-color: $primary-color;
      color: $primary-color;

      i {
        position: absolute;
        font-size: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &.btn-collapse {
      .btn-arrow {
        position: absolute;
        font-size: 20px;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: .3s;
      }
    }

    .has-selected {
      position: absolute;
      bottom: -5px;
      right: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: $primary-color;
      transition: .3s;
      color: $white;
      font-size: 9px;
      text-align: center;
      line-height: 15px;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      z-index: 5;
    }
  }

  &__info {
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.3;
      color: $text-color;
      margin-bottom: 5px;
    }

    &-price {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      padding-left: 10px;
      white-space: nowrap;
    }

    &-additional {
      padding-bottom: 3px;
      font-size: 13px;
      line-height: 1;
      font-weight: normal;
      color: $text-color-light;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .caption {
        font-size: 12px;
      }
    }
  }

  &__collapse-body {
    padding: 0 15px;
    border-bottom: 1px solid $border-color;
  }

}

</style>
