<template>
  <div id="app">
    <div
        :class="`${isShowFooter ? 'be-app--show-footer' : ''}`"
        class="be-app">
      <!-- Main content route -->

      <div class="be-app__route">
        <transition :name="transitionName">
          <router-view/>
        </transition>
      </div>

      <!-- Bottom Toolbar -->
      <be-footer @is-show="showFooter"/>

      <be-confirm-phone-modal :is-show="isHasCustomerPhone"/>

      <be-privacy-policy-modal/>

      <be-language-modal/>

      <be-welcome-screen v-if="isLoading"/>
    </div>
  </div>
</template>

<script>
import BeFooter from "./components/BeFooter";
import {PushNotifications} from "@capacitor/push-notifications";
import {Capacitor} from "@capacitor/core";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import {authToken} from "@/utils/common";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {App} from "@capacitor/app";
import {Device} from "@capacitor/device";
import BeConfirmPhoneModal from "@/components/common/BeConfirmPhoneModal";
import BePrivacyPolicyModal from "@/components/common/BePrivacyPolicyModal";
import user from "@/api/user";
import BeLanguageModal from "@/components/common/BeLanguageModal";
import {loadLanguageAsync} from "@/i18n";
import EventBus from "@/utils/event-bus";
import {openBrowser} from "./utils/common";
import BeWelcomeScreen from "./components/common/BeWelcomeScreen.vue";
// import { Stripe } from '@capacitor-community/stripe';

extend("required", {
  ...required,
  message: "this_field_is_required"
});

const LANGUAGES = ['en','de','fr','it','es'];

export default {
  components: {BeWelcomeScreen, BeLanguageModal, BePrivacyPolicyModal, BeConfirmPhoneModal, BeFooter},
  data() {
    return {
      isLoading: false,
      isShowFooter: true,
      transitionName: "",
      isShowModal: false,
      showModalId: ''
    };
  },
  watch: {
    timer: {
      deep: true,
      handler(newTimer) {
        if (newTimer.countdown === 0) this.redirectToTimeout();
      }
    },
    $route(to, from) {
      if(to.meta.page === from.meta.page) {
        this.transitionName = ""
      } else if(to.meta.page > from.meta.page) {
        this.transitionName = "next"
      } else {
        this.transitionName = "prev"
      }
    }
  },
  computed: {
    venueId() {
      return this.$store.getters.venueId
    },
    timer() {
      return this.$store.getters.timer
    },
    confirmPhoneIdModal() {
      return this.$store.getters.confirmPhoneIdModal
    },
    isHasCustomerPhone() {
      const profile = this.$store.getters.userProfile

      return profile ? !profile.phone && window.navigator.onLine : false
    },
  },
  async beforeCreate() {
    GoogleAuth.initialize();
  },
  async created () {
    await this.appInitialization()
  },
  mounted() {
    this.checkInternet()
    window.addEventListener('offline', () => this.$router.push({name: 'NoInternetConnection'}));
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => this.setShownModal(bvEvent.type === 'shown', modalId))
    this.$root.$on('bv::modal::hidden', () => this.setShownModal(false, ''))

    this.logDeviceInfo();

    if(Capacitor.platform !== 'web') {
      this.registerNotifications();

      App.addListener('backButton', () => {
        if(this.isShowModal && this.showModalId) {
          if(this.showModalId === this.confirmPhoneIdModal) {
            App.exitApp();
          } else {
            this.$bvModal.hide(this.showModalId)
          }
        } else {
          if(this.$route.name === 'Home' || this.$route.name === 'CheckEmail') {
            App.exitApp();
          } else {
            this.$router.back();
          }
        }
      });
    }

    // If the token has expired, will trigger the repeatRequest
    const self = this;
    EventBus.$on('repeatRequest', function (payload) {
      if(payload.isRepeatRequest) {
        self.userProfile();
      }
    })
  },
  beforeDestroy() {
    EventBus.$off('repeatRequest')
  },
  methods: {
    loader(value) {
      this.isLoading = value
    },
    showFooter(payload) {
      this.isShowFooter = payload
    },
    setShownModal(value, id) {
      this.isShowModal = value
      this.showModalId = id
    },
    redirectToTimeout() {
      this.$router.push(`/venues/${this.venueId}/timeout`);
    },
    checkInternet() {
      if(!window.navigator.onLine) {
        this.isLoading = false
        this.$router.push({name: 'NoInternetConnection'})
      }
    },

    async appInitialization() {
      await this.loader(true);
      // if (Capacitor.isPluginAvailable("Stripe")) {
      //   await Stripe.initialize({
      //     publishableKey: process.env.VUE_APP_STRIPE_API_PUBLISH_KEY,
      //   });
      // }
      await this.initCustomer();
      await this.getPrivacyPolicy();
      await this.logDeviceLang();
      await this.loader(false);
    },

    async userProfile() {
      try {
        const response = await user.profile()
        this.$store.commit('setUserProfile', response.data)
      } catch (e) {
        console.log(e)
      }
    },

    async initCustomer() {
      const token = authToken();

      if (token) {
        await this.$store.commit("authorizeUser");
        await this.userProfile();
        await this.$store.dispatch('fetchNotifications');
      }
    },

    async getPrivacyPolicy() {
      try {
        const response = await user.privacyPolicy();
        this.$store.commit('setPrivacyPolicy', response.data)
      } catch (e) {
        console.log(e)
      }
    },

    registerNotifications() {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();

          console.log('Granted')
        } else {
          // Show some error
          console.log('Not Granted')
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
          (token) => {
            console.log('Push registration success, token: ' + token.value);

            this.$store.commit('setFcmToken', token.value);
          }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
          (error) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
          (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
            this.$store.dispatch('fetchNotifications');
          }
      );


      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
          async (notification) => {
            const type = notification.notification.data.type
            const link = notification.notification.data.link
            const notificationId = notification.notification.data.notification_id
            const appointmentId = notification.notification.data.appointment_id

            if(type === 'nps_review' && link) {
              openBrowser(link)
            } else {
              if(appointmentId) {
                this.$router.push({
                  path: '/profile/appointments',
                  query: { type: 'appointment', id: appointmentId }
                });
              }
            }

            if(notificationId) await this.$store.dispatch('readNotification', notificationId)

            // navigator.clipboard.writeText(JSON.stringify(notification));

            console.log('Push action performed: ' + JSON.stringify(notification));
          }
      );
    },

    async getDeliveredNotifications() {
      const notificationList = await PushNotifications.getDeliveredNotifications();
      console.log('delivered notifications', notificationList);
    },

    // Get Device info
    async logDeviceInfo() {
      const info = await Device.getInfo();

      this.$store.commit('setDeviceInfo', info)
    },
    // Get Device lang
    async logDeviceLang() {
      const cacheLang = localStorage.getItem('lang_type')
      const deviceLang = await Device.getLanguageCode();
      const lang = LANGUAGES.includes(deviceLang.value) ? deviceLang.value : LANGUAGES[0]

      if(cacheLang) {
        await this.$store.commit('setAppLanguage', {lang: cacheLang})
        await loadLanguageAsync(cacheLang)
      } else {
        await this.$store.commit('setAppLanguage', {lang: lang})
        await loadLanguageAsync(lang)
      }
    }
  }
}
</script>

